import './scss/styles.scss';

document.addEventListener("DOMContentLoaded", function(event) {
});


var col= false;
$(document).ready( function() {
  $(".navbar-toggler").click( function() {
      col = !col
      if (col) {
        $("nav").removeClass("navbar-light");
          $("nav").addClass("toggler-click");
          $(".icon").removeClass("navbar-toggler-icon");
          $(".icon").addClass("close");
      } else {
        $(".icon").addClass("navbar-toggler-icon");
          $("nav").addClass("navbar-light");
          $("nav").removeClass("toggler-click");
          $(".icon").removeClass("close");
      }
  });
  $("a").on('click', function(event) {

    if (this.hash !== "") {
      event.preventDefault();

      var hash = this.hash;

      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
        window.location.hash = hash;
      });
    } 
  });
});